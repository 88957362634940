<template>
  <div>
    <AppBar />

    <!-- Hero bar -->
    <section class="pt-20">
      <div
        class="items-center w-10/12 grid-cols-2 mx-auto overflow-x-hidden lg:grid md:py-14 lg:py-24 xl:py-14 lg:mt-3 xl:mt-5"
        data-aos="fade-right"
        data-aos-duration="800"
      >
        <div class="pr-2 md:mb-14 py-14 md:py-0">
          <h1 class="text-3xl text-primary font-bold xl:text-5xl lg:text-5xl">
            QR Code, Short URL & Bio Link Generator
          </h1>
          <p class="py-4 text-lg text-gray-500 2xl:py-8 md:py-6 2xl:pr-5">
            Create and customize your QR Codes, shorten URLs, and build bio
            links effortlessly. Streamline sharing with personalized,
            eye-catching designs—all in one place!
          </p>
          <div class="mt-4">
            <a
              href="#contact"
              class="px-5 py-3 text-lg tracking-wider text-success hover:text-white bg-primary-shades rounded-lg md:px-8 hover:bg-primary group"
              ><span>Explore More</span>
            </a>
          </div>
        </div>

        <div class="pb-10 overflow-hidden md:p-10 lg:p-0 sm:pb-0">
          <img
            title="https://storyset.com/technology"
            class="transition-all duration-300 ease-in-out hover:scale-105 lg:w-full sm:mx-auto sm:w-4/6 sm:pb-12 lg:pb-0"
            src="/icons/qr-code-animate.svg"
            alt="Technology illustrations by Storyset"
            width="500"
            height="488"
          />
        </div>
      </div>
    </section>

    <!-- static qrcode -->
    <section class="pt-20">
      <StaticQrcode />
    </section>

    <section class="relative overflow-hidden bg-gray-50">
      <div class="mt-2 md:mt-0 pb-6 sm:py-16 overflow-hidden">
        <div class="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8 relative">
          <div class="relative mt-12 lg:mt-20">
            <div
              class="absolute inset-x-0 hidden xl:px-44 top-2 md:block md:px-20 lg:px-28"
            >
              <svg
                class="w-full"
                xmlns="http://www.w3.org/2000/svg"
                width="875"
                height="48"
                viewBox="0 0 875 48"
                fill="none"
              >
                <path
                  d="M2 29C20.2154 33.6961 38.9915 35.1324 57.6111 37.5555C80.2065 40.496 102.791 43.3231 125.556 44.5555C163.184 46.5927 201.26 45 238.944 45C312.75 45 385.368 30.7371 458.278 20.6666C495.231 15.5627 532.399 11.6429 569.278 6.11109C589.515 3.07551 609.767 2.09927 630.222 1.99998C655.606 1.87676 681.208 1.11809 706.556 2.44442C739.552 4.17096 772.539 6.75565 805.222 11.5C828 14.8064 850.34 20.2233 873 24"
                  stroke="#D4D4D8"
                  stroke-width="3"
                  stroke-linecap="round"
                  stroke-dasharray="1 12"
                />
              </svg>
            </div>
            <div
              class="relative grid grid-cols-1 text-center gap-y-8 sm:gap-y-10 md:gap-y-12 md:grid-cols-3 gap-x-12"
            >
              <div>
                <div
                  class="flex items-center justify-center w-16 h-16 mx-auto bg-white border-2 border-gray-200 rounded-full shadow"
                >
                  <span class="text-xl font-semibold text-primary">1</span>
                </div>
                <h3
                  class="mt-4 sm:mt-6 text-xl font-semibold leading-tight text-gray-900 md:mt-10"
                >
                  Register
                </h3>
                <p
                  class="mt-3 sm:mt-4 text-base text-gray-600 dark:text-gray-400"
                >
                  Register with your email or using sign up with goolgle
                </p>
              </div>
              <div>
                <div
                  class="flex items-center justify-center w-16 h-16 mx-auto bg-white border-2 border-gray-200 rounded-full shadow"
                >
                  <span class="text-xl font-semibold text-primary">2</span>
                </div>
                <h3
                  class="mt-4 sm:mt-6 text-xl font-semibold leading-tight text-gray-900 md:mt-10"
                >
                  Create your image
                </h3>
                <p
                  class="mt-3 sm:mt-4 text-base text-gray-600 dark:text-gray-400"
                >
                  Choose AI assistants to create your image variations.
                </p>
              </div>
              <div>
                <div
                  class="flex items-center justify-center w-16 h-16 mx-auto bg-white border-2 border-gray-200 rounded-full shadow"
                >
                  <span class="text-xl font-semibold text-primary">3</span>
                </div>
                <h3
                  class="mt-4 sm:mt-6 text-xl font-semibold leading-tight text-gray-900 md:mt-10"
                >
                  Download
                </h3>
                <p
                  class="mt-3 sm:mt-4 text-base text-gray-600 dark:text-gray-400"
                >
                  Download zip of all variations
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- short URL  -->
    <section class="pt-20">
      <div
        class="items-center w-10/12 grid-cols-2 mx-auto overflow-x-hidden lg:grid md:py-14 lg:py-24 xl:py-14 lg:mt-3 xl:mt-5"
        data-aos="fade-right"
        data-aos-duration="800"
      >
        <div class="pb-10 overflow-hidden md:p-10 lg:p-0 sm:pb-0">
          <img
            title="https://storyset.com/technology"
            class="transition-all duration-300 ease-in-out hover:scale-105 lg:w-full sm:mx-auto sm:w-4/6 sm:pb-12 lg:pb-0"
            src="/icons/shorturl-animate.svg"
            alt="Technology illustrations by Storyset"
            width="500"
            height="488"
          />
        </div>
        <div class="pr-2 md:mb-14 py-14 md:py-0">
          <h1 class="text-3xl text-primary font-bold xl:text-5xl lg:text-5xl">
            Short URL
          </h1>
          <p class="py-4 text-lg text-gray-500 2xl:py-8 md:py-6 2xl:pr-5">
            Iszify lets you easily reduce long URLs from platforms like
            Instagram, Facebook, YouTube, Twitter, LinkedIn, and other
            authoritative websites. Use your shortened URLs in publications,
            ads, affiliate marketing, blogs, forums, and more. Track stats for
            your projects with our click counter!
          </p>
          <div class="mt-4">
            <a
              href="#contact"
              class="px-5 py-3 text-lg tracking-wider text-success hover:text-white bg-primary-shades rounded-lg md:px-8 hover:bg-primary group"
              ><span>Get Started</span>
            </a>
          </div>
        </div>
      </div>
    </section>

    <!--  -->
    <section class="pt-20">
      <div
        class="items-center w-10/12 grid-cols-2 mx-auto overflow-x-hidden lg:grid md:py-14 lg:py-24 xl:py-14 lg:mt-3 xl:mt-5"
        data-aos="fade-right"
        data-aos-duration="800"
      >
        <div class="pr-2 md:mb-14 py-14 md:py-0">
          <h1 class="text-3xl text-primary font-bold xl:text-5xl lg:text-5xl">
            Link In Bio
          </h1>
          <p class="py-4 text-lg text-gray-500 2xl:py-8 md:py-6 2xl:pr-5">
            Create and customize your QR Codes, shorten URLs, and build bio
            links effortlessly. Streamline sharing with personalized,
            eye-catching designs—all in one place!
          </p>
          <div class="mt-4">
            <a
              href="#contact"
              class="px-5 py-3 text-lg tracking-wider text-success hover:text-white bg-primary-shades rounded-lg md:px-8 hover:bg-primary group"
              ><span>Explore More</span>
            </a>
          </div>
        </div>

        <div class="pb-10 overflow-hidden md:p-10 lg:p-0 sm:pb-0">
          <img
            title="https://storyset.com/technology"
            class="transition-all duration-300 ease-in-out hover:scale-105 lg:w-full sm:mx-auto sm:w-4/6 sm:pb-12 lg:pb-0"
            src="/icons/bio-animate.svg"
            alt="Technology illustrations by Storyset"
            width="500"
            height="488"
          />
        </div>
      </div>
    </section>

    <!--  -->
    <section
      class="relative z-10 overflow-hidden bg-primary py-28 px-8 md:px-12 mt-40"
    >
      <div class="container">
        <div class="-mx-4 flex flex-wrap items-center">
          <div class="w-full px-4 lg:w-1/2">
            <div class="text-center lg:text-left">
              <div class="mb-10 lg:mb-0">
                <h1
                  class="mt-0 mb-3 text-3xl font-bold leading-tight sm:text-4xl sm:leading-tight md:text-[40px] md:leading-tight text-white"
                >
                  Start building automated serverless forms
                </h1>
                <p
                  class="w-full text-base font-medium leading-relaxed sm:text-lg sm:leading-relaxed text-white"
                ></p>
              </div>
            </div>
          </div>
          <div class="w-full px-4 lg:w-1/2">
            <div class="text-center lg:text-right">
              <a
                class="font-semibold rounded-lg mx-auto inline-flex items-center justify-center bg-white py-4 px-9 hover:bg-opacity-90"
                href="#"
                >Create Your First Form</a
              >
            </div>
          </div>
        </div>
      </div>
      <span class="absolute top-0 right-0 -z-10">
        <svg
          width="388"
          height="250"
          viewBox="0 0 388 220"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            opacity="0.05"
            d="M203 -28.5L4.87819e-05 250.5L881.5 250.5L881.5 -28.5002L203 -28.5Z"
            fill="url(#paint0_linear_971_6910)"
          ></path>
          <defs>
            <linearGradient
              id="paint0_linear_971_6910"
              x1="60.5"
              y1="111"
              x2="287"
              y2="111"
              gradientUnits="userSpaceOnUse"
            >
              <stop offset="0.520507" stop-color="white"></stop>
              <stop offset="1" stop-color="white" stop-opacity="0"></stop>
            </linearGradient>
          </defs></svg></span
      ><span class="absolute top-0 right-0 -z-10"
        ><svg
          width="324"
          height="250"
          viewBox="0 0 324 220"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            opacity="0.05"
            d="M203 -28.5L4.87819e-05 250.5L881.5 250.5L881.5 -28.5002L203 -28.5Z"
            fill="url(#paint0_linear_971_6911)"
          ></path>
          <defs>
            <linearGradient
              id="paint0_linear_971_6911"
              x1="60.5"
              y1="111"
              x2="287"
              y2="111"
              gradientUnits="userSpaceOnUse"
            >
              <stop offset="0.520507" stop-color="white"></stop>
              <stop offset="1" stop-color="white" stop-opacity="0"></stop>
            </linearGradient>
          </defs></svg></span
      ><span class="absolute top-4 left-4 -z-10"
        ><svg
          width="43"
          height="56"
          viewBox="0 0 43 56"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g opacity="0.5">
            <circle
              cx="40.9984"
              cy="1.49626"
              r="1.49626"
              transform="rotate(90 40.9984 1.49626)"
              fill="white"
            ></circle>
            <circle
              cx="27.8304"
              cy="1.49626"
              r="1.49626"
              transform="rotate(90 27.8304 1.49626)"
              fill="white"
            ></circle>
            <circle
              cx="14.6644"
              cy="1.49626"
              r="1.49626"
              transform="rotate(90 14.6644 1.49626)"
              fill="white"
            ></circle>
            <circle
              cx="1.49642"
              cy="1.49626"
              r="1.49626"
              transform="rotate(90 1.49642 1.49626)"
              fill="white"
            ></circle>
            <circle
              cx="40.9984"
              cy="14.6642"
              r="1.49626"
              transform="rotate(90 40.9984 14.6642)"
              fill="white"
            ></circle>
            <circle
              cx="27.8304"
              cy="14.6642"
              r="1.49626"
              transform="rotate(90 27.8304 14.6642)"
              fill="white"
            ></circle>
            <circle
              cx="14.6644"
              cy="14.6642"
              r="1.49626"
              transform="rotate(90 14.6644 14.6642)"
              fill="white"
            ></circle>
            <circle
              cx="1.49642"
              cy="14.6642"
              r="1.49626"
              transform="rotate(90 1.49642 14.6642)"
              fill="white"
            ></circle>
            <circle
              cx="40.9984"
              cy="27.8302"
              r="1.49626"
              transform="rotate(90 40.9984 27.8302)"
              fill="white"
            ></circle>
            <circle
              cx="27.8304"
              cy="27.8302"
              r="1.49626"
              transform="rotate(90 27.8304 27.8302)"
              fill="white"
            ></circle>
            <circle
              cx="14.6644"
              cy="27.8302"
              r="1.49626"
              transform="rotate(90 14.6644 27.8302)"
              fill="white"
            ></circle>
            <circle
              cx="1.49642"
              cy="27.8302"
              r="1.49626"
              transform="rotate(90 1.49642 27.8302)"
              fill="white"
            ></circle>
            <circle
              cx="40.9984"
              cy="40.9982"
              r="1.49626"
              transform="rotate(90 40.9984 40.9982)"
              fill="white"
            ></circle>
            <circle
              cx="27.8304"
              cy="40.9963"
              r="1.49626"
              transform="rotate(90 27.8304 40.9963)"
              fill="white"
            ></circle>
            <circle
              cx="14.6644"
              cy="40.9982"
              r="1.49626"
              transform="rotate(90 14.6644 40.9982)"
              fill="white"
            ></circle>
            <circle
              cx="1.49642"
              cy="40.9963"
              r="1.49626"
              transform="rotate(90 1.49642 40.9963)"
              fill="white"
            ></circle>
            <circle
              cx="40.9984"
              cy="54.1642"
              r="1.49626"
              transform="rotate(90 40.9984 54.1642)"
              fill="white"
            ></circle>
            <circle
              cx="27.8304"
              cy="54.1642"
              r="1.49626"
              transform="rotate(90 27.8304 54.1642)"
              fill="white"
            ></circle>
            <circle
              cx="14.6644"
              cy="54.1642"
              r="1.49626"
              transform="rotate(90 14.6644 54.1642)"
              fill="white"
            ></circle>
            <circle
              cx="1.49642"
              cy="54.1642"
              r="1.49626"
              transform="rotate(90 1.49642 54.1642)"
              fill="white"
            ></circle>
          </g>
        </svg>
      </span>
    </section>

    <!-- Testimonial -->
    <TestimonialSection />

    <!--  -->
    <section>
      <h2
        class="text-accent text-3xl md:text-5xl mt-5 text-center pt-5 md:pt-20 font-bold"
      >
        Frequently Asked Questions
      </h2>
      <div class="container mx-auto p-5 w-full gap-10 md:gap-20 py-10 md:py-20">
        <div class="space-y-4 mt-5 md:mt-0 w-full">
          <div v-for="(item, index) in items" :key="index" class="border-t">
            <button
              @click="toggle(index)"
              class="flex justify-between w-full p-4 text-left text-lg font-medium text-gray-700 rounded-lg focus:outline-none"
            >
              <span class="font-medium">{{ item.title }}</span>
              <span v-if="activeIndex === index">-</span>
              <span v-else>+</span>
            </button>
            <div>
              <div
                v-show="activeIndex === index"
                class="p-4 text-gray-600 bg-gray-50 rounded-lg transition ease-in-out"
              >
                {{ item.content }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- Footer -->
    <FooterSection />
  </div>
</template>

<script>
import FooterSection from "@/components/FooterSection.vue";
import TestimonialSection from "@/components/TestimonialSection.vue";
import StaticQrcode from "@/components/qrcodes/StaticQrcode.vue";
import AppBar from "@/components/AppBar.vue";

export default {
  components: {
    FooterSection,
    TestimonialSection,
    StaticQrcode,
    AppBar,
  },

  data() {
    return {
      activeIndex: null,
      items: [
        {
          title: "What is  Workspace?",
          content:
            " Workspace is a coworking space that provides meeting and training spaces, coworking spaces, private offices, executive offices, and virtual offices tailored to the needs of freelancers, startups, small businesses, and corporate teams.",
        },
        {
          title: "Where is  Workspace located?",
          content:
            " 2nd Floor, Wing 5, 4C Idowu Martins Street, Victoria Island. Lagos.",
        },
        {
          title: "What are your operating hours?",
          content:
            "Our general operating hours are Monday - Sunday, but members with 24/7 access can use the facilities at any time.",
        },
        {
          title: "How can I contact  Workspace?",
          content:
            "You can contact us via phone numbers, emails, or through our contact form on the website.",
        },
        {
          title: "How do I sign up for a private office or book a tour?",
          content:
            "You can  book a tour through our website or by contacting our team directly.",
        },
        {
          title: "What is included with a private office rental?",
          content:
            "Private offices come fully furnished with desks and chairs. They include access to all common areas, meeting rooms, and office amenities.",
        },
        {
          title: "What size private offices are available?",
          content:
            "We offer a range of private office sizes, from single-person offices to larger spaces suitable for teams. Please contact us for availability and more details.",
        },
        {
          title: "Are the executive offices different from private offices?",
          content:
            "Yes, executive offices are typically larger, with premium furnishings and additional services such as dedicated phone lines and enhanced administrative support.",
        },
        {
          title: "Do you provide any business services?",
          content:
            "Yes, we offer various business services including mail handling, receptionist services, and administrative support.",
        },
        {
          title: "What types of events can be held at  Workspace?",
          content:
            "Our spaces are suitable for meetings, training sessions, workshops, seminars, and networking events. We also host community events for our members.",
        },
        {
          title: "Are there any shared spaces included each services?",
          content:
            "Yes, every services have access to shared spaces such as lounges, kitchen areas, and common areas for relaxation and informal meetings.",
        },
      ],
    };
  },
  methods: {
    toggle(index) {
      this.activeIndex = this.activeIndex === index ? null : index;
    },
  },
};
</script>

<style scoped></style>
